import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import NavBar from './NavBar';
import Panel from './Panel';
import Bottom from './Bottom';
import nowpayments from '../images/nowpayments.svg';
import axios from 'axios';
function Pay(props) {
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  var { state } = useLocation();
  //console.log('Message superAdmin ', superAdmin);

  // Function to handle back button click
  const handleBackButton = () => {
      // Navigate to the home page when the back button is clicked
      navigate('/pay');
  };

  useEffect(() => {
      // Listen for the 'popstate' event which is triggered when the back button is clicked
      window.addEventListener('popstate', handleBackButton);

      return () => {
          // Remove the event listener when the component is unmounted
          window.removeEventListener('popstate', handleBackButton);
      };
  }, []);

  const [statement, setStatement] = useState([]);
  const [payButtonUrl, setPayButtonUrl] = useState();
  const [paymentAmount, setPaymentAmount] = useState(100);//change minimal payment amount
  const [tokens, setTokens] = useState(55555);
  const [bonus, setBonus] = useState(0);
  const handleAmountChange = (event) => {
      let amount = parseFloat(event.target.value);
     //console.log('amount ',amount);
      
      let calculatedTokens = 0;
      let calculatedBonus = 0;
      const comission = state.values.comission/100;
      //const comission = 0.09;
      //console.log('comission ',comission)
      //if (amount <= 1000) { calculatedBonus = 0; } else
      //if (amount <= 5000) { calculatedBonus = 1; } else { calculatedBonus = 2; };
      calculatedTokens = amount/comission;
      //console.log('calculatedTokens ',calculatedTokens);
      setTokens(calculatedTokens);
      setBonus(calculatedBonus);
      //setPaymentAmount(isNaN(amount) ? 0 : amount);
      setPaymentAmount(isNaN(amount) ? 0 : amount);
    
  };
  const statementData = async () => {
    try {
      const res = await axios.post('/payment/statement', {
        user_code: state.values.user_code,
        agent_code: state.values.agent_code,
      });
      //console.log('response from statement:', res.data);
      setStatement(res.data);
    } catch (err) {
      //console.log(err.response.request.status);
      if (err.response.request.status === 400) {
        alert("statementData failed!");
      }
      if (err.response.request.status === 500) {
        alert("Internal Server Error!");
      }
    }
  };
  const payButton = async (paymentAmount) => {
    try {
      if (paymentAmount < 99) { alert(paymentAmount + 'USD is too small! Minimal payment amount is 100USD') }
      else {
        const res = await axios.post('/payment/pay', {
          user_code: state.values.user_code,
          agent_code: state.values.agent_code,
          price_amount: paymentAmount,
          price_currency: "usd",
        });
        //console.log('response from pay:', res.data.invoice_url);
        setPayButtonUrl(res.data.invoice_url);
      }
    } catch (err) {
      console.log(err.response.data.code);
    }
  };
  useEffect(() => {
    statementData();
    axios
      .post('/login', state.values)
      .then((res) => {
        if (res.data.length !== 0) {
          const values = res.data;
          navigate('/pay', { state: { values } });
        } else {
          alert('No record existed');
        }
      })
      .catch((err) => {
        console.log(err.response.request.status);
        if (err.response.request.status === 401) {
          alert('Invalid credentials');
        } else if (err.response.request.status === 404) {
          alert('User not found');
        } else if (err.response.request.status === 500) {
          alert('Internal Server Error');
        }
      });
  }, []);
  useEffect(() => {
    if (payButtonUrl) {
      const delay = 2000;
      const timer = setTimeout(() => {
        window.open(payButtonUrl, '_blank');
        //window.open(payButtonUrl, '_self');
      }, delay);

      return () => clearTimeout(timer);
    }
  }, [payButtonUrl]);
  /*
  useEffect(() => {
    handleAmountChange(); // Call handleAmountChange when paymentAmount changes
  }, [paymentAmount]);
  */
  // Function to calculate the sum of "Price Currency" for finished payments
  const calculateSum = (payments) => {
    try {
      const finishedPayments = payments.filter((payment) => payment.payment_status === 'finished');
      //console.log('Finished Payments:', finishedPayments);

      const numericPayments = finishedPayments.filter((payment) => !isNaN(parseFloat(payment.price_amount)));
      //console.log('Numeric Payments:', numericPayments);

      const sum = numericPayments.reduce((acc, payment) => acc + parseFloat(payment.price_amount), 0);
      //console.log('Sum:', sum);

      return sum;
    } catch (error) {
      console.error('Error calculating sum:', error);
      return 0;
    }
  };


  return (
    <React.StrictMode>
      <div className="d-flex w-100 vh-100 justify-content-center align-items-start">
        <div className="d-flex w-10 vh-100 justify-content-center align-items-start"><NavBar state={state} /></div>
        <div className="d-grid w-90 vh-1000 p-2 rounded bg-white">
          <div className="d-flex w-100 vh-10 justify-content-center align-items-start"><Panel state={state} /></div>
          <h2>Payments</h2>
          <h5>Payments accepted in crypto currencies only. Minimal payment amount is 100USD. Initially we open Jili 350+ games with free 2 test credits. 
          After you prepay 500USD or more we open 12+ rest iGaming providers and 1200+ games more.</h5>
          <div className="row">
            <div className="form-control d-flex w-100 vh-20 justify-content-around align-items-start">
              <div className="mb-2">
                <label htmlFor="role"><strong>Currency:</strong></label>
                <span>{state.values.currency}</span>
              </div>
              <div className="mb-2">
                <label htmlFor="name"><strong>API Fee:</strong></label>
                <span>{state.values.comission}%</span>
              </div>
            </div>
            <div className="form-control d-flex w-100 vh-20 justify-content-around align-items-start">
            <div className="mb-2">
                <label htmlFor="role"><strong>Currency Rate (USD:{state.values.currency}):</strong></label>
                <span>1:{state.values.currency_rate}</span>
              </div>
              <div className="mb-2">
                <label htmlFor="name"><strong>Calculation:</strong></label>
                <span>100USD=100USD*{state.values.currency_rate}/({state.values.comission}/100)=1428.5714 tokens</span>
              </div>
          </div>
          </div>
          {/* Input field for entering payment amount */}
          <div className="gap-3 d-flex justify-content-around mt-4 p-3">
            <label htmlFor="paymentAmount" className="form-label">
              Enter Payment Amount:
            </label>
            <input
              type="number"
              className="form-control"
              id="paymentAmount"
              value={paymentAmount}
              onChange={(event)=>handleAmountChange(event)}
            />
            <button onClick={() => payButton(paymentAmount)} className='btn btn-success w-100 rounded-0 blinking-text'>Pay {paymentAmount}USD<br />Deposit amount {tokens} tokens{/*<br/>Bonus {bonus}%*/}</button>
          </div>
          {/* Buttons */}
          <div className="form-control ">Powered by <img src={nowpayments} alt="Powered by NowPayments" className="img-fluid" /> </div>
          
          <div>
          </div>
          <div>
            <div className="mb-2 justify-content-center align-items-start">User {state.values.name} Payments - Total: {calculateSum(statement.allPaymentsUser)} USD</div>
            <table className="mb-2 justify-content-center align-items-start">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Currency</th>
                  <th>Payment-ID</th>
                  <th>Status</th>
                  <th>Amount</th>
                  <th>Currency</th>
                  <th>Created</th>
                </tr>
              </thead>
              <tbody>
                {statement.allPaymentsUser?.map((payment) => (
                  <tr key={payment.id}>
                    <td>{payment.id}</td>
                    <td>{payment.pay_currency}</td>
                    <td>{payment.payment_id}</td>
                    <td>{payment.payment_status}</td>
                    <td>{payment.price_amount}</td>
                    <td>{payment.price_currency}</td>
                    <td>{new Date(payment.createdAt).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/** 
          {state.values.role==="user" ? (
                      <div>
                      <div className="mb-2 justify-content-center align-items-start">User {state.values.name} Payments - Total: {calculateSum(statement.allPaymentsUser)} USD</div>
                      <table className="mb-2 justify-content-center align-items-start">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Currency</th>
                            <th>Payment-ID</th>
                            <th>Status</th>
                            <th>Amount</th>
                            <th>Currency</th>
                            <th>Created</th>
                          </tr>
                        </thead>
                        <tbody>
                          {statement.allPaymentsUser?.map((payment) => (
                            <tr key={payment.id}>
                              <td>{payment.id}</td>
                              <td>{payment.pay_currency}</td>
                              <td>{payment.payment_id}</td>
                              <td>{payment.payment_status}</td>
                              <td>{payment.price_amount}</td>
                              <td>{payment.price_currency}</td>
                              <td>{payment.createdAt}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      </div>
          ) : (
            <div>
            <div className="mb-2 justify-content-center align-items-start">Agent {state.values.name} Payments - Total: {calculateSum(statement.allPaymentsAgent)} USD</div>
            <table className="mb-2 justify-content-center align-items-start">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Currency</th>
                  <th>Payment ID</th>
                  <th>Status</th>
                  <th>Amount</th>
                  <th>Price,Currency</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {statement.allPaymentsAgent?.map((payment) => (
                  <tr key={payment.id}>
                    <td>{payment.id}</td>
                    <td>{payment.pay_currency}</td>
                    <td>{payment.payment_id}</td>
                    <td>{payment.payment_status}</td>
                    <td>{payment.price_amount}</td>
                    <td>{payment.price_currency}</td>
                    <td>{payment.createdAt}</td>
                  </tr>
                ))}
              </tbody>
                </table> 
                </div>
          )}
**/}

          <Bottom />
        </div>
      </div>
    </React.StrictMode>
  );
}

export default Pay;
