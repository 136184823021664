import React from 'react';
import { Link, useLocation } from 'react-router-dom';
function Bottom(props) {
    const { state } = useLocation();
    const serverTime = new Date().toLocaleString('en-US', {
        //timeZone: 'UTC', // Specify the timezone (change 'UTC' to your desired timezone)
        weekday: 'short', // Output the abbreviated weekday (e.g., Mon)
        year: 'numeric', // Output the full year (e.g., 2024)
        month: 'short', // Output the abbreviated month (e.g., Jan)
        day: 'numeric', // Output the day of the month (e.g., 1)
        hour: '2-digit', // Output the hour (e.g., 01)
        minute: '2-digit', // Output the minute (e.g., 00)
        second: '2-digit', // Output the second (e.g., 00)
    });
    return (
        <React.StrictMode>
            <div>
            <Link to="/pay" className='mt-2 mb-2 btn btn-success w-100 rounded-0' state={state}><strong>Get full access to 1000+ games</strong></Link>
            </div>
            <div className="mt-2 mb-2 d-flex w-100 vh-10 justify-content-center align-items-start bg-success">
                <div className="d-flex w-10 vh-10 justify-content-center align-items-start text-white">
                   Highconnect.Net Gaming API v.2.1.8. {serverTime} &copy; 2020-2024
                </div>
            </div>
        </React.StrictMode>
    );
}

export default Bottom;
